<template>
  <RegistoChaveApi />
</template>

<script>
import RegistoChaveApi from "@/components/gestao/api/RegistoChaveApi.vue";

export default {
  components: {
    RegistoChaveApi
  }
};
</script>
